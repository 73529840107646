import clsx from 'clsx';
import React, { useState, useRef } from 'react';
import Footer from './footer';
import Header from './header';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {RoutePath} from '../constants/constant';
import {QrReader} from "react-qr-reader";
import {Modal} from "antd";
import {toast} from "react-toastify";
import {useAppDispatch} from "../redux/store";
import { setRemoteDeviceId } from "../redux/auth/commonSlice";

export const DefaultLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const qrcodeRef = useRef();
  return (
    <div className="flex flex-no-wrap h-screen bg-gray-100 overflow-hidden">
      {/*<Sidebar showMenuMobile={showMenuMobile} onToggleMenuMobile={() => setShowMenuMobile(!showMenuMobile)} />*/}
      <div
        className={clsx(
          'main-content flex flex-1 flex-col z-10 bg-gray-100',
          'flex flex-col',
        )}
      >
        {location.pathname !== RoutePath.LOGIN && (
          <Header
            onToggleMenuMobile={() => setShowMenuMobile(!showMenuMobile)}
            onQrcodePress={()=> {setIsModalOpen(true)}}
          />
        )}
        <div className="overflow-auto h-full">
          <Outlet />
        </div>
          {/*<Modal title="Connect To TV" footer={null} open={isModalOpen} onOk={()=>{}} onCancel={()=>{setIsModalOpen(false)}}>*/}
          {/*    Please scan qrcode appear on your TV screen*/}
          {/*    <QrReader*/}
          {/*        ref={(ref) => {qrcodeRef = ref }}*/}
          {/*        onResult={(result, error) => {*/}
          {/*            if (!!result) {*/}
          {/*                localStorage.setItem(REMOTE_DEVICE_ID, result?.getText())*/}
          {/*                setIsModalOpen(false);*/}
          {/*                dispatch(setRemoteDeviceId(result?.getText()));*/}
          {/*                qrcodeRef.current.stopCamera();*/}
          {/*            }*/}

          {/*            if (!!error) {*/}
          {/*                console.info(error);*/}
          {/*                // toast(error.message);*/}
          {/*            }*/}
          {/*        }}*/}
          {/*        // style={{ width: '100%' }}*/}
          {/*     constraints={{facingMode: 'environment'}}/>*/}
          {/*</Modal>*/}
        {location.pathname == RoutePath.LOGIN && <Footer />}
      </div>
    </div>
  );
};

export default DefaultLayout;
