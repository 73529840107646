import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import axiosCustomClient from "../../apis/axiosCustomClient";

export const searchSong = createAsyncThunk(
  types.SEARCH_SONG,
  async (request: { keyword: string; }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.SEARCH_SONG, {
          keyword: request.keyword,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
          console.log(err);
          store.dispatch(appActions.hideLoading());
          throw err;
      });
  },
);

export const playOutUrl = createAsyncThunk(
    types.PLAY_OUT_URL,
    async (request: { hashVersion: string; }) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.PLAY_OUT, {
                hashVersion: request.hashVersion,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                console.log(err);
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const singNowOnTv = createAsyncThunk(
    types.SING_NOW,
    async (request: { play_queue_id: string; track_id: number, token: string }) => {
        store.dispatch(appActions.showLoading());
        return axiosCustomClient
            .post(API.PLAY_QUEUE_PLAY_NOW, {
                play_queue_id: request.play_queue_id,
                track_id: request.track_id,
                token: request.token,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                console.log(err);
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);




