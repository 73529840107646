export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
export const ACTIVATED_DEVICE = 'ACTIVATED_DEVICE';
export const CHANNEL_ID = 'CHANNEL_ID';
export const USER_INFO = 'USER_INFO';
export const DEVICE_ID = 'DEVICE_ID';
export const TV_DEVICE_ID = 'TV_DEVICE_ID';
export const PLAY_QUEUE_ID = 'PLAY_QUEUE_ID';

// export const REMOTE_DEVICE_ID = 'REMOTE_DEVICE_ID';
export const ResponseStatus = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
};
export const RoutePath = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  PASS_PHRASE: '/passphrase',
  EDIT_PROFILE: '/edit-profile',
  FORGOT_PASSWORD: '/forgot-password',
};
