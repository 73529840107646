import {PLAY_OUT_URL} from "../redux/types";

export const API = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  BASE_URL: process.env.REACT_APP_BASE_URL || '',
  BASE_URL_EASTERN_BLU: process.env.REACT_APP_BASE_URL_EASTERN_BLU || '',
  // PREFIX: 'api/v1/',
  SIGN_IN: '/v1/auth/sessions.json',
  ACTIVATE_TV_API: '/v1/auth/tv_token.json',
  PROFILE_ME_API: '/v1/me.json',
  POST_PAIRING_TOKEN_API: '/v4/pairings/mobile',
  PAIRING_FORGET_CHANNEL_API: '/v4/pairings/forget_channel',
  PLAY_QUEUE_SING_NOW: '/v4/play_queue/sing_now',
  PLAY_QUEUE_PLAY_NOW: '/v4/play_queue/play_now',

  CHANGE_PASSWORD: 'account/change-password',
  RESET_PASSWORD: 'account/reset-password',
  FORGOT_PASSWORD: 'account/forgot-password',
  UPDATE_PROFILE: 'account/update-profile',
  BALANCE: 'account/balance',
  SIGN_UP: 'account/signup',
  LOGOUT: 'account/logout',
  GET_DATA_SIGN_UP: 'account/new',

  //song endpoint
  SEARCH_SONG: 'song/search',
  PLAY_OUT: 'song/play-out-url',
};
