export const REMOTE_ACTION = {
    // CONTROLLER_JOINED: 'CONTROLLER_JOINED',
    // ADD_SONG_TO_QUEUE: 'ADD_SONG_TO_QUEUE',
    // NEXT_SONG: 'NEXT_SONG',
    // SYNC_QUEUE: 'SYNC_QUEUE',
    // GET_QUEUE: 'GET_QUEUE',
    // VOCAL: 'VOCAL',
    // DELETE_SONG: 'DELETE_SONG',
    // VOLUME_UP: 'VOLUME_UP',
    // VOLUME_DOWN: 'VOLUME_DOWN',
    // TOGGLE_QUEUE: 'TOGGLE_QUEUE',

    // Play Queue
    PLAY_QUEUE_PLAY_NOW: 'play_now',
    PLAY_QUEUE_ADD_TO_QUEUE : 'add_to_queue',
    PLAY_QUEUE_BRING_TOP : 'bring_to_top',
    PLAY_QUEUE_DELETE_QUEUE : 'delete_from_queue',
    PLAY_QUEUE_CLEAR_ALL : 'clear_all',
    PLAY_QUEUE_SKIP_INTRO : 'skip_intro',

    // General Remote
    GENERAL_REMOTE_UP : 'up',
    GENERAL_REMOTE_DOWN : 'down',
    GENERAL_REMOTE_LEFT : 'left',
    GENERAL_REMOTE_RIGHT : 'right',
    GENERAL_REMOTE_OK : 'ok',
    GENERAL_REMOTE_BACK : 'go_back',

    // Exoplayer
    PLAYER_PREVIOUS : 'previous',
    PLAYER_PLAY : 'play',
    PLAYER_PAUSE : 'pause',
    PLAYER_NEXT : 'next',
    PLAYER_VOLUME_UP : 'volume_up',
    PLAYER_VOLUME_DOWN : 'volume_down',
    PLAYER_TOGGLE_VOCALS_ON : 'toggle_vocals_on',
    PLAYER_TOGGLE_VOCALS_OFF : 'toggle_vocals_off',
    PLAYER_TRACK_SPEED_UP : 'track_speed_up',
    PLAYER_TRACK_SPEED_DOWN : 'track_speed_down',
    PLAYER_TRACK_SPEED_RESET : 'track_speed_reset',
    PLAYER_PITCH_UP : 'track_pitch_up',
    PLAYER_PITCH_DOWN : 'track_pitch_down',
    PLAYER_PITCH_RESET : 'track_pitch_reset',
};
