import React, {useContext, useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import {Switch} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolumeUp, faVolumeDown, faBackward, faForward, faPause, faPlay} from "@fortawesome/free-solid-svg-icons";
import { config } from 'dotenv';
import LandingContext from "../../components/context/LandingContext";
import {REMOTE_ACTION} from "../../utils/constants";
import {toast} from "react-toastify";

export const Playlist: React.FC = () => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    const {
        currentChannel,
        connected,
    } = useContext(LandingContext);

  const prevSong = () => {
      if(currentChannel){
          currentChannel.publish(REMOTE_ACTION.PLAYER_TOGGLE_VOCALS_OFF,'');
      }else {
          toast('device is not connected')
      }
  };
  const nextSong = () => {
      if(currentChannel){
          currentChannel.publish(REMOTE_ACTION.PLAYER_NEXT, JSON.stringify({data: REMOTE_ACTION.PLAYER_NEXT}));
          toast('Next Song');
      }else {
          toast('device is not connected')
      }
  };
  const pauseSong = () => {
      if(currentChannel){
          currentChannel.publish(REMOTE_ACTION.PLAYER_PAUSE, JSON.stringify({data: REMOTE_ACTION.PLAYER_PAUSE}));
          toast('Volume down');
      }else {
          toast('device is not connected')
      }
  };

    const playSong = () => {
        if(currentChannel){
            currentChannel.publish(REMOTE_ACTION.PLAYER_PLAY, JSON.stringify({data: REMOTE_ACTION.PLAYER_PLAY}));
            toast('Volume down');
        }else {
            toast('device is not connected')
        }
    };

  const volumeUp = () => {
      if(currentChannel){
          currentChannel.publish(REMOTE_ACTION.PLAYER_VOLUME_UP, JSON.stringify({data: REMOTE_ACTION.PLAYER_VOLUME_UP}));
          toast('Volume up');
      }else {
          toast('device is not connected')
      }
  }

  const volumeDown = () => {
      if( currentChannel){
          currentChannel.publish(REMOTE_ACTION.PLAYER_VOLUME_DOWN, JSON.stringify({data: REMOTE_ACTION.PLAYER_VOLUME_DOWN}));
          toast('Volume down');
      }else {
          toast('device is not connected')
      }
  }
  return (
    <div className="px-4 flex flex-col" style={{ height: '60vh' }}>
        <div className="my-6">
            <span className="icon my-6  text-3xl mx-1">
                <FontAwesomeIcon style={{ marginRight: 40}} icon={faVolumeUp} color={'blue'} onClick={volumeUp}/>
                <FontAwesomeIcon icon={faVolumeDown} color={'blue'} onClick={volumeDown}/>
            </span>

        </div>
        <div className="my-6">
          <span className="icon  text-3xl mx-1">
              <FontAwesomeIcon style={{ marginRight: 40}} icon={faForward} color={'blue'} onClick={nextSong}/>
              <FontAwesomeIcon style={{ marginRight: 40}} icon={faPause} color={'blue'} onClick={pauseSong}/>
              <FontAwesomeIcon icon={faPlay} color={'blue'} onClick={playSong}/>
          </span>
        </div>

    </div>
  );
};
