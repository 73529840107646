import React, {useContext, useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { getBalance } from '../../redux/home/homeThunk';
import store, {useAppDispatch, useAppSelector} from '../../redux/store';
import moment from 'moment/moment';
import {getMachineId} from "../../utils/helpers";
import QRCode from "react-qr-code";
import * as rdd from 'react-device-detect';
import {AutoComplete, Avatar, Button, Input, List, Modal} from "antd";
import {searchSong} from "../../redux/song/songThunk";
import {toast} from "react-toastify";
import {SongModel} from "../../redux/song/songSlice";
import {REMOTE_ACTION} from "../../utils/constants";
// @ts-ignore
import imageDefault from '../../assets/images/song_default.png';
import { use } from 'video.js/dist/types/tech/middleware';
import LandingContext from "../../components/context/LandingContext";
import {json} from "react-router-dom";
import {activateTv, getChannelId, signIn} from "../../redux/auth/authThunk";
import {
    ACCESS_TOKEN,
    ACTIVATED_DEVICE,
    CHANNEL_ID,
    PAIR_DEVICE,
    PLAY_QUEUE_ID,
    RoutePath, TV_DEVICE_ID
} from "../../constants/constant";
import * as Ably from "ably";
import {Types} from "ably";
import {appActions} from "../../redux/app/appSlice";

export const Device: React.FC = () => {
  const dispatch = useAppDispatch();
    // @ts-ignore
    const {
        currentChannel,
        setCurrentChannel,
        connected,
    } = useContext(LandingContext);
  const [activatePopupVisible, setActivatePopupVisible] = useState(false)
  const [pairPopupVisible, setPairPopupVisible] = useState(false)
  const [shortCode, setShortCode] = useState('')
  const [connectionCode, setConnectionCode] = useState('')
  const deviceActivated = localStorage.getItem(ACTIVATED_DEVICE);
  const channel = localStorage.getItem(CHANNEL_ID);
  console.log(deviceActivated);
  console.log(channel);
    const activateDevice = () => {
      if (shortCode.length > 0) {
          dispatch(
              activateTv({
                  token: localStorage.getItem(ACCESS_TOKEN) || '',
                  short_code: shortCode.toUpperCase(),
              }),
          )
              .unwrap()
              .then((response) => {
                  console.log(response);
                  localStorage.setItem(ACTIVATED_DEVICE, 'true');
                  setActivatePopupVisible(false);
              }).catch((error) => {
                  toast('something went wrong')
              console.log(error);
          })
      }
  }

  const pairToDevice = () => {
     console.log(connectionCode);
      dispatch(
          getChannelId({
              party_code: connectionCode,
          }),
      )
          .unwrap()
          .then((response) => {
              // "id": 3168313,
              //     "play_queue_id": 241397,
              //     "tv_device_channel": {
              //     "id": "e5081aca-c9eb-49df-97b9-fa6d72953208",
              //         "name": "demo's KHub",
              //         "tv_device_uid": "dbfa9d73f69f2ed7"
              // }

              console.log('PairTvResponse', response.data);
              const channelId = response.data.tv_device_channel.id;
              const playQueueId = response.data.play_queue_id;
              const tvDeviceId = response.data.tv_device_channel.tv_device_uid;
              let options: Ably.Types.ClientOptions = {
                  key: 'jTeRHw.uoBPeA:3rAWvCpsyZ_Wq3yUJBAeOAPjmumXxUcdoHk-khgGWtc',
                  clientId: getMachineId()
              };
              let client = new Ably.Realtime(options); /* inferred type Ably.Realtime */
              client.connection.once('connected', () => {
                  console.log(getMachineId());
                  toast('Device connected');
                  let channel = client.channels.get(channelId); /* inferred type Ably.Types.RealtimeChannel */
                  channel.unsubscribe();
                  channel.subscribe(function (message: Types.Message) {
                      console.log(message);
                      if (message.clientId != getMachineId()) {
                          console.log(message.clientId, message.name, message.id);
                      }
                  });
                  channel.presence.get(function (err: any, presenceSet: any) {
                      console.log(presenceSet);
                      channel.publish(REMOTE_ACTION.CONTROLLER_JOINED, 'joined');
                  });
                  channel.presence.enter("khub-remote", function (err: any, presenceSet: any) {
                      console.log('entered');
                      localStorage.setItem(CHANNEL_ID, channelId);
                      localStorage.setItem(PLAY_QUEUE_ID, playQueueId);
                      localStorage.setItem(TV_DEVICE_ID, tvDeviceId);
                      setPairPopupVisible(false)
                      if (setCurrentChannel) {
                          setCurrentChannel(channel);
                      }
                  })
              });
          }).catch((err) => {
              setPairPopupVisible(false);
          toast('Something went wrong');
      });
  }

  return (
    <div className="w-full h-full overflow-hidden p-4" style={{ height: '60vh' }}>
        <div className="md:w-1/3 mx-auto p-10  border rounded-xl">
            <div className="grid items-center justify-center">
                <button
                    disabled={deviceActivated != null}
                    type="submit"
                    onClick={() => setActivatePopupVisible(true)}
                    className={
                        deviceActivated != null? 'px-4 py-3 rounded-md bg-gray-500 w-full mt-5 text-gray-50 text-white'
                    :'px-4 py-3 rounded-md bg-blue-500 w-full mt-5 text-gray-50 text-white'}
                >
                    { deviceActivated != null? "Activated TV Successfully" :"Activate Khub TV"}
                </button>

                <button
                    // disabled={deviceActivated != null}
                    type="submit"
                    onClick={() => {
                        if(channel) {
                            localStorage.setItem(CHANNEL_ID, '');
                            if (setCurrentChannel) {
                                setCurrentChannel(null);
                            }
                        }else {
                            setPairPopupVisible(true)
                        }
                    }}
                    className={
                        channel? 'px-4 py-3 rounded-md bg-gray-500 w-full mt-5 text-gray-50 text-white'
                            :'px-4 py-3 rounded-md bg-blue-500 w-full mt-5 text-gray-50 text-white'}
                >
                    { channel? "Unpair" :"Pair To TV"}
                </button>
                <Modal
                    title="Enter Activate Code"
                    visible={activatePopupVisible}
                    onOk={activateDevice}
                    onCancel={() => { setActivatePopupVisible(false)}}
                    footer={[
                        <Button key="back" onClick={() => { setActivatePopupVisible(false)}}>
                            Cancel
                        </Button>,
                        <Button key="submit" onClick={activateDevice}>
                            Ok
                        </Button>
                    ]}
                >
                    <Input placeholder="Enter activation code" onChange={(e) => {
                        setShortCode(e.target.value);
                    }} ></Input>
                </Modal>

                <Modal
                    title="Enter Connection code"
                    visible={pairPopupVisible}
                    onOk={pairToDevice}
                    onCancel={() => { setPairPopupVisible(false)}}
                    footer={[
                        <Button key="back" onClick={() => { setPairPopupVisible(false)}}>
                            Cancel
                        </Button>,
                        <Button key="submit" onClick={pairToDevice}>
                            Ok
                        </Button>
                    ]}
                >
                    <Input placeholder="Enter activation code" onChange={(e) => {
                        setConnectionCode(e.target.value);
                    }} ></Input>
                </Modal>
            </div>
        </div>
    </div>
  );
};

