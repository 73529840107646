import { createAsyncThunk } from '@reduxjs/toolkit';
import * as types from '../types';
import axiosClient from '../../apis/axiosClient';
import store from '../store';
import { appActions } from '../app/appSlice';
import { API } from '../../apis/api';
import { SignUpType } from '../../pages/sign_up/sign_up.type';
import axiosCustomClient from "../../apis/axiosCustomClient";
import {ACCESS_TOKEN} from "../../constants/constant";

export const initData = createAsyncThunk(
    types.INIT_DATA,
    async () => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .get(API.GET_DATA_SIGN_UP)
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const signIn = createAsyncThunk(
  types.SIGN_IN,
  async (request: { username: string; password: string,  device_id: string}) => {
    store.dispatch(appActions.showLoading());
    return axiosCustomClient
      .post(API.SIGN_IN, {
          email: request.username,
          password: request.password,
          device_id: request.device_id,
          client_id: 'e8697ca8bab1083c119580b402a053129a6379e2e0885017facf6fe73704df01',
          scope: 'public remote_mode',
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        console.log(response.data);
        return response;
      })
      .catch((err) => {
        console.log(err);
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


export const forgotPassword = createAsyncThunk(
    types.FORGOT_PASSWORD,
    async (request: { email: string }) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.FORGOT_PASSWORD, {
                email: request.email,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);
export const resetPassword = createAsyncThunk(
    types.RESET_PASSWORD,
    async (request: { password: string }) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.RESET_PASSWORD, {
                email: request.password,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const changePassword = createAsyncThunk(
  types.CHANGE_PASSWORD,
  async (request: {
    old_password: string;
    new_password: string;
    confirm_new_password: string;
  }) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.CHANGE_PASSWORD, {
        old_password: request.old_password,
        new_password: request.new_password,
        confirm_new_password: request.confirm_new_password,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);

export const signUp = createAsyncThunk(
  types.SIGN_UP,
  async (request: SignUpType) => {
    store.dispatch(appActions.showLoading());
    return axiosClient
      .post(API.SIGN_UP, {
        role: request.role,
        username: request.username,
          first_name: request.first_name,
          last_name: request.last_name,
        email: request.email,
        password: request.password,
        country: request.country,
        city: request.city,
        address: request.address,
        mobile: request.mobile,
        postal_zip: request.postal_zip,
        company_name: request.company_name,
        public_key: request.public_key,
      })
      .then((response) => {
        store.dispatch(appActions.hideLoading());
        return response;
      })
      .catch((err) => {
        store.dispatch(appActions.hideLoading());
        throw err;
      });
  },
);


export const updateProfile = createAsyncThunk(
    types.UPDATE_PROFILE,
    async (request: SignUpType) => {
        store.dispatch(appActions.showLoading());
        return axiosClient
            .post(API.UPDATE_PROFILE, {
                email: request.email,
                country: request.country,
                city: request.city,
                address: request.address,
                mobile: request.mobile,
                postal_zip: request.postal_zip,
                company_name: request.company_name,
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const logoutUser = createAsyncThunk(types.LOGOUT, async () => {
  store.dispatch(appActions.showLoading());
  return axiosClient
    .get(API.LOGOUT)
    .then((response) => {
      store.dispatch(appActions.hideLoading());
      return response;
    })
    .catch((err) => {
      store.dispatch(appActions.hideLoading());
      throw err;
    });
});

export const activateTv = createAsyncThunk(
    types.ACTIVATE_TV,
    async (request: {short_code: string, token: string}) => {
        store.dispatch(appActions.showLoading());
        return axiosCustomClient
            .post(API.ACTIVATE_TV_API, {
                short_code: request.short_code,
                token: request.token
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);

export const getChannelId = createAsyncThunk(
    types.GET_CHANNEL_ID,
    async (request: {party_code: string}) => {
        store.dispatch(appActions.showLoading());
        return axiosCustomClient
            .post(API.POST_PAIRING_TOKEN_API, {
                party_code: request.party_code,
                user_id: '242719',
                token: localStorage.getItem(ACCESS_TOKEN)
            })
            .then((response) => {
                store.dispatch(appActions.hideLoading());
                return response.data;
            })
            .catch((err) => {
                store.dispatch(appActions.hideLoading());
                throw err;
            });
    },
);
