import React, {useContext, useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import { getBalance } from '../../redux/home/homeThunk';
import { useAppDispatch } from '../../redux/store';
import moment from 'moment/moment';
import {getMachineId} from "../../utils/helpers";
import QRCode from "react-qr-code";
import * as rdd from 'react-device-detect';
import {RadioChangeEvent, Tabs} from "antd";
import {Search} from "../search";
import {Playlist} from "../play_list";
import {QueuingList} from "../queuing_list";
import LandingContext from "../../components/context/LandingContext";
import {REMOTE_ACTION} from "../../utils/constants";
import {Device} from "../device";
import {searchSong} from "../../redux/song/songThunk";

export const HomeMobile: React.FC = () => {
   type TabPosition = 'left' | 'right' | 'top' | 'bottom';
    const dispatch = useAppDispatch();
  const [tabPosition, setTabPosition] = useState<TabPosition>('bottom');
    const {
        currentChannel,
        connected,
    } = useContext(LandingContext);
  const changeTabPosition = (index: string) => {
    if(index === '3') {
        if(currentChannel && connected) {
            console.log('request queue');
            currentChannel.publish(REMOTE_ACTION.GET_QUEUE, '');
        }
    }
  };

    return (
      <div className='w-full h-full'>
      <Tabs
          tabBarStyle={{ justifyContent: "space-between", height: "100%" }}
          centered
          tabPosition={tabPosition}
          onChange={(index) => changeTabPosition(index)}
          // @ts-ignore
          items={new Array(3).fill(null).map((_, i) => {
              const id = String(i + 1);
              if(i===0) {
                  return {
                      label: 'Device',
                      key: id,
                      children: <Device/>
                  };
              } else if(i===1) {
                  return {
                      label: 'Search',
                      key: id,
                      children: <Search/>
                  };
              }else if(i===2) {
                  return {
                      label: 'Control',
                      key: id,
                      children: <Playlist/>
                  };
              }
          })}
      />
      </div>
  );
};
