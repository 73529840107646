import React, {useContext, useEffect, useState} from 'react';
// @ts-ignore
import logo from '../../assets/icons/logo.jpeg';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {searchSong, singNowOnTv} from "../../redux/song/songThunk";
import {toast} from "react-toastify";
import {SongModel} from "../../redux/song/songSlice";
import {REMOTE_ACTION} from "../../utils/constants";
// @ts-ignore
import imageDefault from '../../assets/images/song_default.png';
import LandingContext from "../../components/context/LandingContext";
import algoliasearch from 'algoliasearch/lite';
import { Hit as AlgoliaHit } from 'instantsearch.js';
import {Highlight, Hits, InfiniteHits, InstantSearch, SearchBox} from "react-instantsearch";
import { Button, Card, Flex, Typography } from 'antd';
import {ACCESS_TOKEN, PLAY_QUEUE_ID} from "../../constants/constant";

const cardStyle: React.CSSProperties = {
    width: '100%',
};

const imgStyle: React.CSSProperties = {
    width: 70,
    height: 70
};

export const Search: React.FC = () => {
    const searchClient = algoliasearch(
        'L4GXQCHLR5',
        '5f0406c8dd4022b4ce7c5a48ffaa1aa6'
    );
  const dispatch = useAppDispatch();
    // @ts-ignore
    const {
        currentChannel,
        connected,
    } = useContext(LandingContext);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const { searchResult } = useAppSelector((state)=>state.song);
  const [ keyword, setKeyword ] = useState<string>();

  const singNow = (track_id: number) => {
      dispatch(
          singNowOnTv({
              play_queue_id: localStorage.getItem(PLAY_QUEUE_ID) || '',
              track_id,
              token: localStorage.getItem(ACCESS_TOKEN) || '',
          }),
      )
          .unwrap()
          .then((response) => {
              currentChannel.publish(REMOTE_ACTION.PLAY_QUEUE_PLAY_NOW, JSON.stringify({data: REMOTE_ACTION.PLAY_QUEUE_PLAY_NOW}));
          })
  }

    type HitProps = {
        hit: AlgoliaHit<{
            id: number;
            title: string;
            poster_url: string;
            artists: [];
        }>;
    };
    function Hit({ hit }: HitProps) {
        return (
            <>
                <Card hoverable style={cardStyle} styles={{ body: { padding: 0, overflow: 'hidden' } }}>
                    <Flex>
                        <img
                            alt="avatar"
                            src={hit.poster_url}
                            style={imgStyle}
                        />
                        <Flex vertical align="flex-start" style={{ padding: 10 }}>
                            <span className="Hit-price" style={{fontSize: 15}}>{hit.title}</span>
                            <span className="Hit-price" style={{fontSize: 15}}>{hit.artists.join(', ')}</span>
                        </Flex>
                        <Flex vertical align="flex-end" justify="space-between" style={{ padding: 10 }}>
                            {currentChannel &&
                                <Button style={{}} onClick={ () => {singNow (hit.id)}}>
                                    Sing Now
                                </Button>
                            }
                        </Flex>
                    </Flex>
                </Card>
            </>
        );
    }


  return (
    <div className="w-full h-full overflow-hidden p-4" style={{overflowY: 'scroll', height: '80vh'}}>
      {/*<AutoComplete*/}
      {/*    style={{ width: '100%' }}*/}
      {/*    onSearch={handleSearch}*/}
      {/*    value={keyword}*/}
      {/*    placeholder="Enter search"*/}
      {/*    options={options}*/}
      {/*/>*/}
      {/*<List*/}
      {/*    style={{ height: '100%',paddingTop: 10, overflow: 'auto' }}*/}
      {/*    className='overflow-hidden'*/}
      {/*    itemLayout="horizontal"*/}
      {/*    dataSource={searchResult || []}*/}
      {/*    renderItem={(item, index) => (*/}
      {/*        <List.Item actions={[<Button type="default" onClick={() => addItemToQueue(item)}>Add To Queue</Button>]}>*/}
      {/*          <List.Item.Meta*/}
      {/*              avatar={<Avatar src={item.image? item.image : imageDefault} />}*/}
      {/*              title={item.songTitle}*/}
      {/*              description="This is karaoke version"*/}
      {/*          />*/}
      {/*        </List.Item>*/}
      {/*    )}*/}
      {/*/>*/}
        <InstantSearch indexName="Track_production" searchClient={searchClient}>
            <SearchBox placeholder="Search" autoFocus  style={{ width: '100%' }}/>
            <InfiniteHits showPrevious hitComponent={Hit} />
        </InstantSearch>
    </div>
  );
};

