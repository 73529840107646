//SMS login
export const INIT_DATA = 'INIT_DATA';
export const BALANCE = 'BALANCE';
export const SIGN_IN = 'SIGN_IN';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGN_UP = 'SIGN_UP';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOGOUT = 'LOGOUT';
export const SEARCH_SONG='SEARCH_SONG';
export const PLAY_OUT_URL='PLAY_OUT_URL';

export const ACTIVATE_TV = 'ACTIVATE_TV';
export const GET_CHANNEL_ID = 'GET_CHANNEL_ID';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SING_NOW='SING_NOW';
