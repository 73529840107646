import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Types} from "ably";
import {CHANNEL_ID, USER_INFO} from "../../constants/constant";

export interface CommonState {
  remoteDeviceId?: string;
  currentChannel?: Types.RealtimeChannelBase;
  userInfo?: string
}
const initialState: CommonState = {
  remoteDeviceId: localStorage.getItem(CHANNEL_ID) || '',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setRemoteDeviceId(state, action: PayloadAction<string>) {
      state.remoteDeviceId = action.payload;
    },
    setUserInfo(state, action: PayloadAction<string>) {
      state.userInfo = action.payload;
    },
  }
});

// Actions
export const {
  setRemoteDeviceId,
  setUserInfo
} = commonSlice.actions;

// Reducer
const commonReducer = commonSlice.reducer;
export default commonReducer;
